import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import './MemberDetail.css';

const MemberDetail = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";
  const { bioguideId } = useParams();
  const [member, setMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sponsoredBills, setSponsoredBills] = useState([]);
  const [showSponsoredBills, setShowSponsoredBills] = useState(false);

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/member/${bioguideId}?format=json&api_key=${apiKey}`
        );
        setMember(response.data.member);
      } catch (error) {
        console.error("Error fetching member data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMember();
  }, [bioguideId]);

  const fetchSponsoredBills = async () => {
    try {
      const response = await axios.get(
        `https://api.congress.gov/v3/member/${bioguideId}/sponsored-legislation?format=json&api_key=${apiKey}`
      );
      setSponsoredBills(response.data.sponsoredLegislation);
    } catch (error) {
      console.error("Error fetching sponsored bills:", error);
    }
  };

  const toggleSponsoredBills = () => {
    if (!showSponsoredBills && sponsoredBills.length === 0) {
      fetchSponsoredBills();
    }
    setShowSponsoredBills(!showSponsoredBills);
  };

  const calculateYearsServed = (terms) => {
    let houseYears = 0;
    let senateYears = 0;

    terms.forEach(term => {
      const years = (term.endYear || new Date().getFullYear()) - term.startYear;
      if (term.chamber === "House of Representatives") {
        houseYears += years;
      } else if (term.chamber === "Senate") {
        senateYears += years;
      }
    });

    return { houseYears, senateYears };
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;
  if (!member) return <div>No member data available.</div>;

  const { houseYears, senateYears } = calculateYearsServed(member.terms);

  return (
    <div className="member-detail-container" style={{ 
      margin: '100px auto 0', 
      maxWidth: '1400px',
      padding: '0 80px', 
      width: '100%',
      boxSizing: 'border-box'
    }}>
      <div className="member-detail-box" style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '40px 60px',
        overflow: 'hidden'
      }}>
        <div className="member-detail-content" style={{ 
          display: 'flex', 
          alignItems: 'flex-start'
        }}>
          <div className="member-detail-text" style={{ 
            flex: 1, 
            paddingRight: '40px'
          }}>
            <h1>{member.directOrderName}</h1>
            <p><strong>BioGuide ID:</strong> {member.bioguideId}</p>
            <p><strong>Party:</strong> {member.partyHistory[0]?.partyName || "Not available"}</p>
            <p><strong>State:</strong> {member.state || "Not available"}</p>
            <p><strong>Birth Year:</strong> {member.birthYear || "Not available"}</p>
            <p><strong>Current Member:</strong> {member.currentMember ? "Yes" : "No"}</p>
            
            <h2>Years Served</h2>
            <p><strong>House of Representatives:</strong> {houseYears} years</p>
            <p><strong>Senate:</strong> {senateYears} years</p>
            
            {member.terms && member.terms.length > 0 && (
              <>
                <h2>Current Term</h2>
                <p><strong>Chamber:</strong> {member.terms[member.terms.length - 1].chamber || "Not available"}</p>
                <p><strong>Term Start:</strong> {member.terms[member.terms.length - 1].startYear || "Not available"}</p>
                <p><strong>Term End:</strong> {member.terms[member.terms.length - 1].endYear || "Ongoing"}</p>
              </>
            )}
            
            {member.addressInformation && (
              <>
                <h2>Office Information</h2>
                <p>{member.addressInformation.officeAddress}</p>
                <p>Phone: {member.addressInformation.phoneNumber}</p>
              </>
            )}
            
            {member.officialWebsiteUrl && (
              <p><strong>Official Website:</strong> <a href={member.officialWebsiteUrl} target="_blank" rel="noopener noreferrer">{member.officialWebsiteUrl}</a></p>
            )}
            
            <h2>Legislation</h2>
            <p><strong>Sponsored:</strong> {member.sponsoredLegislation.count}</p>
            <p><strong>Cosponsored:</strong> {member.cosponsoredLegislation.count}</p>
            
            <button 
              onClick={toggleSponsoredBills}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '10px 15px',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '10px'
              }}
            >
              {showSponsoredBills ? 'Hide' : 'Show'} Sponsored Legislation
            </button>
            
            {showSponsoredBills && (
              <div style={{ marginTop: '20px' }}>
                <h3>Sponsored Legislation</h3>
                {sponsoredBills.length > 0 ? (
                  <ul style={{ paddingLeft: '20px' }}>
                    {sponsoredBills.map((bill, index) => (
                      bill.title && (
                        <li key={index} style={{ marginBottom: '10px' }}>
                          <strong>{bill.type} {bill.number}</strong>: {bill.title}
                        </li>
                      )
                    ))}
                  </ul>
                ) : (
                  <p>Loading sponsored bills...</p>
                )}
              </div>
            )}
          </div>
          {member.depiction?.imageUrl && (
            <div className="member-detail-image" style={{ 
              flexShrink: 0, 
              width: '200px',
              marginTop: '20px'
            }}>
              <img 
                src={member.depiction.imageUrl} 
                alt={member.directOrderName} 
                style={{ 
                  maxWidth: '100%', 
                  height: '240px', 
                  objectFit: 'cover',
                  display: 'block'
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberDetail;
