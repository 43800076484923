import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const Members = ({ apiKey }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/member?format=json&offset=${offset}&limit=20&api_key=${apiKey}`
        );
        setMembers((prevMembers) => [...prevMembers, ...response.data.members]);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchMembers();
  }, [apiKey, offset]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      setOffset((prevOffset) => prevOffset + 20);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="members-content">
      {members.map((member) => (
        <div
          key={member.bioguideId}
          className="standard-member"
          onClick={() => navigate(`/members/${member.bioguideId}`)}
        >
          <div className="member-info">
            <h2>{member.name}</h2>
            <p>{member.state}</p>
          </div>
          <img
            src={member.depiction?.imageUrl || ""}
            alt={member.name}
            className="member-image"
          />
        </div>
      ))}
    </div>
  );
};

export default Members;
