import React, { useState } from 'react';
import './styles.css'; // Using the existing styles.css file

const EmailBox = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    // Add your email submission logic here
  };

  return (
    <div className="email-box-container">
    <div className="email-box">
        <h2>Email Sign up</h2>
        <br></br>
      
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="email-input"
        />
        <br></br>
        <button type="submit" className="submit-button">Submit</button>
      </form>
      <p>Sign up for our newsletter to stay up to date on the latest news and events.</p>
      
    </div>
    <br></br>
    <br></br>
    </div>
  );
};

export default EmailBox;

