import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const Bills = ({ apiKey }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/bill?format=json&offset=${offset}&limit=20&api_key=${apiKey}`
        );
        setBills((prevBills) => [...prevBills, ...response.data.bills]);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchBills();
  }, [apiKey, offset]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      setOffset((prevOffset) => prevOffset + 20);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  return (
    <div className="bills-content">
      {bills.map((bill) => (
        <div
          key={`${bill.congress}-${bill.type}-${bill.number}`}
          className="standard-bill"
          onClick={() =>
            navigate(`/bills/${bill.congress}/${bill.type}/${bill.number}`)
          }
        >
          <h2>{bill.title}</h2>
          <p>{bill.latestAction.text}</p>
          <p>
            {bill.type} {bill.number} - {bill.originChamber}
          </p>
          <p>{new Date(bill.latestAction.actionDate).toLocaleDateString()}</p>
        </div>
      ))}
    </div>
  );
};

export default Bills;
