import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "./styles.css"; // Ensure this import is present

const BillDetail = () => {
  const apiKey = "NHdJjzbcGyGWRWiqihZ5SH6FZdChkW2fMVOBh5lT";
  const { congress, type, number } = useParams();
  const [bill, setBill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBill = async () => {
      if (!congress || !type || !number) return; // Ensure parameters are defined

      try {
        const response = await axios.get(
          `https://api.congress.gov/v3/bill/${congress}/${type}/${number}?format=json&api_key=${apiKey}`
        );
        console.log("Fetched bill data:", response.data.bill); // Log the bill data
        setBill(response.data.bill);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    };

    fetchBill();
  }, [congress, type, number]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  if (!bill) return <div>No bill data available.</div>;

  return (
    <div className="bill-detail">
      <h1>{bill.title}</h1>
      <hr />
      <div className="bill-detail-content">
        <div className="info-section">
          <p>
            <strong>Number:</strong> {bill.number}
          </p>
          <p>
            <strong>Chamber of Origin:</strong> {bill.originChamber}
          </p>
          <p>
            <strong>Introduced Date:</strong>{" "}
            {new Date(bill.introducedDate).toLocaleDateString()}
          </p>
          <p>
            <strong>Latest Action:</strong> {bill.latestAction.text}
          </p>
          {bill.sponsors && bill.sponsors.length > 0 && (
            <>
              {bill.sponsors.map((sponsor, index) => (
                <div key={index}>
                  <p>
                    <strong>Sponsor:</strong>{" "}
                    <Link to={`/member/${sponsor.bioguideId}`}>
                      {sponsor.fullName}
                    </Link>
                  </p>
                </div>
              ))}
            </>
          )}
          {bill.url && (
            <p>
              <strong>URL:</strong>{" "}
              <a href={bill.url} target="_blank" rel="noopener noreferrer">
                {bill.url}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillDetail;
